import React, {useState} from 'react';
import {connect} from 'react-redux';
import {UserOutlined} from '@ant-design/icons';
import {Menu, Dropdown, Avatar} from 'antd';
import {alert, confirm} from 'devextreme/ui/dialog';
import styles from './style.module.scss';
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Button, TextBox} from 'devextreme-react';
import {LoaderService} from '../../../../../RetroDCS.System/LoaderService';
import apiClient from '../../../../../services/axios';

const mapStateToProps = ({settings}) => ({
  AppUser: settings.AppUser,
  Status: settings.Status
});

const ProfileMenu = (props) => {
  const {dispatch} = props;
  const [modal, setModal] = useState(false);
  const [passwordUpdate, setPasswordUpdate] = useState({
    initialPassword: '',
    confirmPassword: ''
  });

  const logout = e => {
    e.preventDefault();
    dispatch({
      type: 'user/LOGOUT',
    });
  };

  const editPassword = e => {
    e.preventDefault();
    confirm('Are you sure you wish to update your password?<br />Upon successful update you will be logged out<br />and further you will have to login again with the<br />'
      + 'new password. Are you sure you wish to continue?', 'Warning!').then(resp => {
      if (resp) {
        setModal(true);
      }
    });
  };

  const onModalClose = () => {
    setModal(!modal);
    setPasswordUpdate({
      ...passwordUpdate,
      initialPassword: '',
      confirmPassword: ''
    });
  };

  const closeBtn = (
    <button className="close" onClick={onModalClose}>
      &times;
    </button>
  );

  const valueChange = e => {
    let prop = e.component.option('property');
    setPasswordUpdate({
      ...passwordUpdate,
      [prop]: e.value
    });
  };

  const saveData = () => {
    if (!passwordUpdate.initialPassword)
      return alert('Please enter password', 'Warning!');

    if (!passwordUpdate.confirmPassword)
      return alert('Please enter password in the confirm password field.', 'Warning!');

    if (passwordUpdate.confirmPassword !== passwordUpdate.initialPassword)
      return alert('The entered passwords do not match.', 'Warning!');

    let data = [{
      externalId: props['AppUser']['externalId'],
      initialPassword: passwordUpdate.initialPassword,
      confirmPassword: passwordUpdate.confirmPassword
    }];
    apiClient
      .post('/Administration/UpdateUserPassword', data)
      .then(response => {
        if (response) {
          try {
            LoaderService.setData(false);
            let resp = JSON.parse(response.data);
            if (resp[0].response) {
              dispatch({
                type: 'user/LOGOUT',
              });
            } else {
              return alert(resp[0].message, 'Warning!');
            }
          } catch (e) {
            LoaderService.setData(false);
            return alert(response.data, 'Error!');
          }
        }
      })
      .catch(err => {
        LoaderService.setData(false);
        console.log(err);
        return alert(err, 'Error!');
      });


  };

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <a href="#" onClick={editPassword}>
          <i className="fe fe-user mr-2"/> Edit Password
        </a>
      </Menu.Item>
      <Menu.Divider/>
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2"/>Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Avatar className={styles.avatar} shape="square" size="medium" icon={<UserOutlined/>}/>
        </div>
      </Dropdown>
      <Modal
        keyboard={false}
        isOpen={modal}
        toggle={onModalClose}
        backdrop="static"
        centered={true}
        style={{
          minWidth: 500,
        }}
      >
        <ModalHeader toggle={onModalClose} close={closeBtn}>
          UPDATE USER PASSWORD
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">Password</span>
                </div>
                <TextBox
                  value={passwordUpdate.initialPassword}
                  placeholder="Password"
                  property="initialPassword"
                  className="form-control"
                  mode="password"
                  onValueChanged={valueChange}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">Confirm Password</span>
                </div>
                <TextBox
                  value={passwordUpdate.confirmPassword}
                  placeholder="Confirm Password"
                  property="confirmPassword"
                  className="form-control"
                  mode="password"
                  onValueChanged={valueChange}
                />
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="success" onClick={saveData} text="Save"></Button>
          <Button type="danger" onClick={onModalClose} text="Cancel"></Button>
        </ModalFooter>
      </Modal>
    </div>

  );
};

export default connect(mapStateToProps)(ProfileMenu);
