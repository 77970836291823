import React from 'react';
import AppUserSettings from './AppUser';
import Actions from './Actions';
import UserMenu from './UserMenu';
import style from './style.module.scss';

const TopBar = () => {
  return (
    <div className={style.topbar}>
      <div className="mr-4">
        <AppUserSettings/>
      </div>
      <div className="mr-auto"/>
      {/*<div className="mr-4 d-none d-sm-block">*/}
      {/*  <Actions />*/}
      {/*</div>*/}
      <div className="">
        <UserMenu/>
      </div>
    </div>
  );
};

export default TopBar;
