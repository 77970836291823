import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Menu} from 'antd';
import {Link, withRouter} from 'react-router-dom';
import classNames from 'classnames';
import store from 'store';
import {find} from 'lodash';
import style from './style.module.scss';
import apiClient from 'services/axios';
import {alert} from 'devextreme/ui/dialog';

const mapStateToProps = ({menu, settings}) => ({
  menuData: menu.menuData,
  logo: settings.logo,
  menuColor: settings.menuColor,
  VesselSpan: settings.VesselSpan,
});

const MenuTop = ({
                   menuData = [],
                   location: {pathname},
                   menuColor,
                   logo,
                   dispatch,
                 }) => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || []);
  const [fmsConfig, setFmsConfig] = useState({});
  const [manageDeliveries, setManageDeliveries] = useState({
    show: false,
    isWarehouse: false,
    isVessel: false
  });
  const [menuControl, setMenuControl] = useState({
    pmc: false,
    adm: false,
    epcc: false,
    contractor: false
  });

  useEffect(() => {
  });

  useEffect(() => {

    setMenuControl({
      ...menuControl,
      pmc: store.get('pmc'),
      adm: store.get('adm'),
      epcc: store.get('epcc'),
      contractor: store.get('contractor')
    });

    applySelectedKeys();
    let promiseArray = [];
    promiseArray.push(apiClient.get('/Shared/GetApplicationSettings'));
    Promise.all(promiseArray)
      .then(response => {
        if (response) {
          try {
            let data = JSON.parse(response[0]['data']);

            //#region FmsConfig
            let config = data[0]['_Configuration']['Data'][0];
            setFmsConfig(config);
            changeSetting('Configuration', config);
            //#endregion

            //#region Version Management
            changeSetting('Version', data[1]['Version']);
            //#endregion

            //#region User Details
            const userPermissions = data[2]['_AppUserPermissions']['Data'];
            changeSetting('AppUser', userPermissions);
            //#endregion

            //#region Status
            const status = data[3]['Statuses']['Data'];
            changeSetting('Status', status);
            //#endregion

          } catch (e) {
            return alert(response.data, 'Error!');
          }
          generateMenuItems();
        }
      })
      .catch(err => {
        console.log(err);
        return alert(err, 'Error!');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData]);

  const changeSetting = (setting, value) => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting,
        value,
      },
    });
  };

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item);
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key));
        }
        return flattenedItems;
      }, []);
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname]);
    setSelectedKeys(selectedItem ? [selectedItem.key] : []);
  };

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key]);
    setSelectedKeys([e.key]);
  };

  const generateMenuItems = () => {
    const generateItem = item => {
      const {key, title, url, icon, disabled, count} = item;
      if (item.category) {
        return null;
      }
      //Used to prevent unwanted dashboard links from being generated in menu
      let goAhead = true;

      // menuControl.adm
      // menuControl.pmc
      // menuControl.epcc
      //adminOnly
      //hideEpcc
      //pmcOnly
      //contractorOnly
      if (item.hideEpcc && menuControl.epcc) {
        goAhead = false;
      } else if (item.adminOnly && !menuControl.adm) {
        goAhead = false;
      } else if (item.pmcOnly && !menuControl.pmc) {
        goAhead = false;
      } else if (item.contractorOnly && !menuControl.contractor) {
        goAhead = false;
      }
      if (goAhead) {
        if (item.url) {
          return (
            <Menu.Item key={key} disabled={disabled}>
              {item.target && (
                <a href={url} target={item.target} rel="noopener noreferrer">
                  {icon && <span className={`${icon} ${style.icon}`}/>}
                  <span className={style.title}>{title}</span>
                  {count && <span className="badge badge-success ml-2">{count}</span>}
                </a>
              )}
              {!item.target && (
                <Link to={url}>
                  {icon && <span className={`${icon} ${style.icon}`}/>}
                  <span className={style.title}>{title}</span>
                  {count && <span className="badge badge-success ml-2">{count}</span>}
                </Link>
              )}
            </Menu.Item>
          );
        }
        if (item.hasOwnProperty('isDelivery')) {
          return (
            <Menu.Item key={key} disabled={disabled}>
              <div
                rel="noopener noreferrer"
                onClick={() => {
                  let isWarehouse = false;
                  let isVessel = false;
                  if (item.hasOwnProperty('isWarehouseDelivery')) {
                    isWarehouse = true;
                  }
                  if (item.hasOwnProperty('isVesselDelivery')) {
                    isVessel = true;
                  }
                  setManageDeliveries({
                    ...manageDeliveries,
                    show: true,
                    isWarehouse: isWarehouse,
                    isVessel: isVessel,
                  });
                }}
              >
                {icon && <span className={`${icon} ${style.icon}`}/>}
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
              </div>
            </Menu.Item>
          );
        }
        return (
          <Menu.Item key={key} disabled={disabled}>
            {icon && <span className={`${icon} ${style.icon}`}/>}
            <span className={style.title}>{title}</span>
            {count && <span className="badge badge-success ml-2">{count}</span>}
          </Menu.Item>
        );
      }
    };

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
                  {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`}/>}
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && (
                <span className="badge badge-success ml-2">{menuItem.count}</span>
              )}
                </span>
          );
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          );
        }
        return generateItem(menuItem);
      });

    return menuData.map(menuItem => {
      let goAhead = true;
      if (menuItem.hasOwnProperty('isWarehouse')) {
        goAhead = fmsConfig['WarehouseSupport'];
      }
      if (menuItem.adminOnly && !menuControl.adm) {
        goAhead = false;
      }
      if (goAhead && menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
                {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`}/>}
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && (
              <span className="badge badge-success ml-2">{menuItem.count}</span>
            )}
              </span>
        );
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        );
      }
      if (!goAhead) return;
      return generateItem(menuItem);
    });
  };

  return (
    <div>
      <div
        className={classNames(`${style.menu}`, {
          [style['white']]: menuColor === 'white',
          [style['gray']]: menuColor === 'gray',
          [style['dark']]: menuColor === 'dark',
        })}
      >
        <div className={style.logoContainer}>
          <div className={style.logo}>
            <img src="resources/images/RetroNET.png" className="mr-2" alt="FMS"/>
          </div>
        </div>
        <div className={style.navigation}>
          <Menu
            onClick={handleClick}
            selectedKeys={selectedKeys}
            mode="horizontal"
            className="pull-right"
          >
            {generateMenuItems()}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default withRouter(connect(mapStateToProps)(MenuTop));
