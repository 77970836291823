import axios from 'axios';
import store from 'store';

let querystring = require('querystring');

export const isLocal = () => {
  let obj = window.env.getApiUrl();
  return obj.local;
};

export const baseUrl = () => {
  let obj = window.env.getApiUrl();
  return obj.url;
};
const apiClient = axios.create({
  // baseURL: 'https://retronet-api.conveyor.cloud/api',
  baseURL: baseUrl() + 'api',
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
  // headers: { 'Content-Type': 'application/json' },
});

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken');
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    request.headers.AccessToken = accessToken;
  }
  return request;
});

apiClient.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    const {response} = error;
    const {data} = response;
    if (data) {
      if (data.hasOwnProperty('error_description')) {
        return alert(data.error_description);
      }
      console.log(data);
    }

    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refresh_token = store.get('refresh_token');
      const access_token = await apiClient
        .post(
          '/auth/login',
          querystring.stringify({
            refresh_token: refresh_token,
            grant_type: 'refresh_token',
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        )
        .then(response => {
          if (response) {
            const {access_token, refresh_token} = response.data;
            if (access_token) {
              store.set('accessToken', access_token);
              store.set('refresh_token', refresh_token);
            }
            return access_token;
          }
          return false;
        })
        .catch(err => {
          console.log(err);
        });
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      axios.defaults.headers.common['AccessToken'] = access_token;

      originalRequest.headers.AccessToken = access_token;
      originalRequest.headers.Authorization = 'Bearer ' + access_token;

      return apiClient(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default apiClient;
