import React from 'react';
import {connect} from 'react-redux';
import {Layout} from 'antd';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';
import style from './style.module.scss';

const mapStateToProps = ({settings}) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
});

const AuthLayout = ({
                      children,
                      logo,
                      isGrayTopbar,
                      isCardShadow,
                      isSquaredBorders,
                      isBorderless,
                      authPagesColor,
                    }) => {
  return (
    <Layout>
      <Layout.Content>
        <div
          className={classNames(`${style.container}`, {
            cui__layout__squaredBorders: isSquaredBorders,
            cui__layout__cardsShadow: isCardShadow,
            cui__layout__borderless: isBorderless,
            [style.white]: authPagesColor === 'white',
            [style.gray]: authPagesColor === 'gray',
          })}
          style={{
            backgroundImage: 'url(./resources/images/Retrotech-Wallpaper.png)',
          }}
        >
          <div
            className={classNames(`${style.topbar}`, {
              [style.topbarGray]: true,
            })}
          >
            <div className={style.logoContainer}>
              <div className={style.logo}>
                <img src="resources/images/RetroNET.png" className="mr-2" alt="FMS"/>
              </div>
            </div>
          </div>
          <div className={style.containerInner}>{children}</div>
          <div className="mt-auto pb-5 pt-5">
            {/* <ul
              className={`${style.footerNav} list-unstyled d-flex mb-0 flex-wrap justify-content-center`}
            >
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  Terms of Use
                </a>
              </li>
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  Compliance
                </a>
              </li>
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  Support
                </a>
              </li>
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  Contacts
                </a>
              </li>
            </ul> */}
            <div className="text-center">
              Copyright © 2019-2020 Retrotech SA
              {/* |{' '} <a href="https://www.mediatec.org/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a> */}
            </div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default withRouter(connect(mapStateToProps)(AuthLayout));
