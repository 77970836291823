import apiClient from 'services/axios';
import store from 'store';

const querystring = require('querystring');

export async function login(email, password, captchaToken) {
  return await apiClient
    .post(
      '/auth/login',
      querystring.stringify({
        UserName: email,
        Password: password,
        captchaToken: captchaToken,
        grant_type: 'password',
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    .then(response => {
      if (response) {
        const {
          access_token,
          obj,
          UserFullName,
          refresh_token,
          adm,
          pmc,
          epcc,
          contractor
        } = response.data;
        if (access_token) {
          store.set('accessToken', access_token);
          store.set('refresh_token', refresh_token);
          store.set('obj', obj);
          store.set('adm', adm === 'True');
          store.set('pmc', pmc === 'True');
          store.set('epcc', epcc === 'True');
          store.set('contractor', contractor === 'True');
        }
        return response.data;
      }
      return false;
    })
    .catch(err => {
      console.log(err);
    });
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const {accessToken} = response.data;
        if (accessToken) {
          store.set('accessToken', accessToken);
          // store.set('accessToken', accessToken)
        }
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function currentAccount() {
  return apiClient
    .get('/Authentication/GetCurrentAccount')
    .then(response => {
      if (response) {
        const access_token = response.data;
        if (access_token) {
          store.set('accessToken', access_token);
        }
        return response.data;
      }
      if (!window.pathname === '/') window.location = '/auth/404';
    })
    .catch(err => {
      console.log(err);
    });
}

export async function logout() {
  return apiClient
    .get('/auth/logout')
    .then(() => {
      store.remove('accessToken');
      return true;
    })
    .catch(err => console.log(err));
}
