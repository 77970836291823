import React, {useState, useEffect} from 'react';
import store from 'store';
import {LoadPanel} from 'devextreme-react/load-panel';
import {LoaderService} from 'RetroDCS.System/LoaderService';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

const mapStateToProps = ({settings}) => ({
  AppUser: settings.AppUser
});

const AppUserSettings = (settings) => {
  const [showLoadIndicator, setLoadIndicator] = useState(false);
  const position = {of: '#root'};

  LoaderService.getData().subscribe(message => {
    if (message === undefined) return;
    setLoadIndicator(message.value);
  });

  return (
    <div>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={showLoadIndicator}
        showIndicator={true}
        shading={true}
        showPane={true}
        hideOnOutsideClick={false}
      />
      <strong>{settings['AppUser']['FullName']}</strong>
      <span> [{settings['AppUser']['SubjectMatterExpertName'] + ' - ' + settings['AppUser']['DisciplineName']}]</span>
    </div>
  );
};


export default connect(mapStateToProps)(AppUserSettings);
