import React from 'react';
import style from './style.module.scss';
import {connect} from 'react-redux';

const mapStateToProps = ({settings}) => ({
  Version: settings.Version
});


const Footer = (allSettings) => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <a
          href="http://www.retrotech.ae"
          target="_blank"
          rel="noopener noreferrer"
          className={style.logo}
        >
          RetroDCS - Document Control System
        </a>
        {'  '}
        [v {allSettings.Version}]
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Footer);
